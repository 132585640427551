@import url(/assets/css/bootstrap.css);
@import url(/assets/css/util.css);
@import url(/assets/css/main1.css);
@import url(/assets/css/main.css);
/* @import '/assets/fonts/iconic/font-awesome-4.7.0/css/font-awesome.min.css'; */
/* @import '/assets/fonts/iconic/css/material-design-iconic-font.min.css'; */

/* @import '/assets/css/custom.css';
@import '/assets/css/bootstrap.css';
@import '/assets/css/plugins.css';
@import '/assets/css/structure.css';
@import '/assets/css/main.css';
@import '/assets/css/main1.css';
@import '/assets/css/dash_1.css';
@import '/assets/css/dash_2.css';
@import '/assets/css/theme-checkbox-radio.css.css';
@import '/assets/css/contacts.css';
@import '/assets/css/animate.css';
@import '/assets/css/scrollspyNav.css';
@import '/assets/css/custom-modal.css';
@import '/assets/css/user-profile.css';
@import '/assets/css/tags-input.css';
@import '/assets/css/table-basic.css';
@import '/assets/css/datatables.css';
@import '/assets/css/dt-global_style.css';
@import '/assets/css/file-upload-with-preview.min.css';
@import '/assets/css/perfect-scrollbar.css';
@import '/assets/css/flatpickr.css';
@import '/assets/css/custom-flatpickr.css'; */

:root {
    --primary-theme-color: #d90429;
}
  
.sidebar-theme {
    background: #d90429;
    background: var(--primary-theme-color);
}

a.fr.btn.btn-danger {
    background-color: #d90429;
    background-color: var(--primary-theme-color);
    border-color: #d90429;
    border-color: var(--primary-theme-color);
}

.customSwitchBtn.activeswitchclass .react-switch-bg {
    background: #d90429;
    background: var(--primary-theme-color);
}

.add_btn_icon_style {
    background: #d90429;
    background: var(--primary-theme-color);
}
.navbar-logo {
    width: 140px !important;
    height: auto !important;
}
#userProfileDropdown {
    color: #e0e6ed;
}
.left-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
}

.left-nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    /*color: #444444;*/
    color: #000000;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.left-nav__link:hover, .left-nav__link:hover .material-icons {
    background-color: #eeeeee;
    color: #d82b2a;
    text-decoration: blink;
}

.left-nav__link--active {
    color: #d82b2a;
    /*    border-top: 2px solid;*/
}

.left-nav__icon {
    font-size: 18px;
}

.left__btm_menu, .right__btm_menu {
    width: 50%
}

.left-nav__link.lf {
    width: 45%;
    float: left; 
    margin-top: 5px;
}

.left-nav__link.rt {
    width: 45%;
    float: right;
    margin-top: 5px;
}

.highlight__menu {
    bottom: 27px;
    position: fixed;
    left: 44%;
    padding: 15px 10px 10px 10px;
    border-radius: 50%;
    background: #111111;
}

.highlight__menu i {
    color: #ffffff;
}


.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}
.navbar-logooo
{
    width: 60px;
}

.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}
.navbar-logooo
{
    width: 60px;
}



 
  
  .people-list {
    width: 260px;
    float: left;
  }
  .people-list .search {
    padding: 20px;
  }
  .people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
  }
  .people-list .fa-search {
    position: relative;
    left: -25px;
  }
  .people-list ul {
    padding: 20px;
    height: 770px;
  }
  .people-list ul li {
    padding-bottom: 20px;
  }
  .people-list img {
    float: left;
  }
  .people-list .about {
    float: left;
    margin-top: 8px;
  }
  .people-list .about {
    padding-left: 8px;
  }
  .people-list .status {
    color: #92959E;
  }
  
  .chat {
    width: 100%;
    float: left;
    /* background: #F2F5F8; */
    color: #434651;
  }
  .chat .chat-header {
    padding: 10px 20px;
    border-bottom: 2px solid white;
  }
  .chat .chat-header img {
    float: left;
  }
  .chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
    margin-top: 6px;
  }
  .chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
  }
  .chat .chat-header .chat-num-messages {
    color: #92959E;
  }
  .chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
  }
  .chat .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: scroll;
    height: 75vh;
  }
  .chat .chat-history .message-data {
    margin-bottom: 15px;
  }
  .chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
  }
  .chat .chat-history .message {
    color: white;
    padding: 8px 20px;
    line-height: 25px;
    font-size: 14px;
    border-radius: 7px;
    margin-bottom: 10px;
    width: 90%;
    position: relative;
  }
  
  .chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #EBECF1;
    border-width: 10px;
    margin-left: -10px;
  }
  .chat .chat-history .my-message {
    background: #EBECF1;
    color: #000000;
  }
  .chat .chat-history .other-message {
    background: #d82b2a;
  }
  .chat .chat-history .other-message:after {
    border-bottom-color: #d82b2a;
    left: 93%;
  }
  
  .online, .offline, .me {
    margin-right: 3px;
    font-size: 10px;
  }
  
  .online {
    color: #86BB71;
  }
  
  .offline {
    color: #E38968;
  }
  
  .me {
    color: #d82b2a;
  }
  
  .align-left {
    text-align: left;
  }
  
  .align-right {
    text-align: right;
  }
  
  .float-right {
    float: right;
  }
  
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  
  .no-pad{
    padding-left: 0px;
    padding-right: 0px;
  }
  
  header.cd-main-header.js-cd-main-header{
    width: 100%;
  }
  
  .cd-main-header{
    position: fixed;
  }
  
  .custom-top-h5{
    width: 100%;
  /*  text-align: center;*/
  /*  position: absolute;*/
    font-size: 14px;
  }
  .activestatus_color
  {
    background: #EF233C;
    padding: 1px;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 8px;
    color: white;
  }
.lb-flt-rg {
    float: right;
}
.flt-lft {
    float: left !important;
}
.lbl-fix-lbl-section .lbl-fix-lbl {
    font-size: 13px;
}


.formcustom {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}

.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
    
}
.cursorpointer
{
    cursor: pointer;
}

.formcustom {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}


.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}
.profile_padd_left
{
    margin-left: 26% !important;
}
.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}
.profile_padd_left
{
    margin-left: 26% !important;
}

.react-datepicker-wrapper
{
    display: block !important;
    /* width: 0%; */
}
.form_cut_dtyle
{
    width: 23%;
    float: left;
    margin-right: 30px;
}
.set_mini_height
{
    min-height: 300px;
}
.cust_top
{
    margin-top: 35px;
}

.cust_style_dislaynone
{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 30px;
}


.cont__xs {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}
.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}

span.tc_user_lbl_expand {
    width: 6%;
    display: inline-block;
}
.cont__xs {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}

.padding_sty
{
    padding: 5px;
}
.checkbox_cust_style
{
    padding: 10px;
    padding-top: 0px;
}
.cont__xs {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}

.padding_sty
{
    padding: 5px;
}
.checkbox_cust_style
{
    padding: 10px;
    padding-top: 0px;
}

.right__menu_cr.text-center.m-b-5 {
    width: 33%;
    float: left;
}
.right__menu_cr.text-center.m-b-5 img {
    width: 140px;
    height: 150px;
}
.cursorpointer
{
    cursor: pointer;
}
.Suspend_user
{
    background-color: #8dbf42 !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    /* width: 45%; */
    color: white;
    cursor: pointer;
}
.unSuspend_user
{
    background-color:#e7515a !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    /* width: 45%; */
    color: white;
    cursor: pointer;
}
.cursorpointer
{
    cursor: pointer;
}

.switchery-default.on {
    background-color: #40a944;
    border-color: #40a944;
    box-shadow: #40a944 0px 0px 0px 10px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;  
}

.switchery-default.on > small {
    left: 17px;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
}

.switchery-default.off {
    background-color: rgb(255, 255, 255);
    border-color: rgb(223, 223, 223);
    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
}

.switchery-default.off > small {
    left: 0px;
    transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
}

.switchery {
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    display: block;
    line-height: 1;
    width: 2.25rem;
    height: 1.125rem;
    position: relative;
    box-sizing: content-box;
    border-radius: 100px;
}

.switchery>small {
    background-color: #fff;
    width: 1.125rem;
    height: 1.125rem;
    position: absolute;
    top: -0.125px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    border-radius: 100px;
}
.form-group label, label {
    color: #111111;
}

.Suspend_user_tech
{
    background-color: #8dbf42 !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    width: 70%;
    color: white;
    cursor: pointer;
}
.unSuspend_user_tech
{
    background-color:#e7515a !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    width:  70%;
    color: white;
    cursor: pointer;
}
/* .table1
{
    widows: 100% !important;
    overflow-x: scroll;
} */

span.tc_user_lbl {
    width: 20%;
    display: inline-block;
}
.customTechModalDetails {
    height: 80vh;
    overflow: auto;
}
.Company_formcustom__1pCxc {
    
}

.Company_container__Jf-kL.Company_formcustom__1pCxc {
    
}
