.cursorpointer
{
    cursor: pointer;
}
.Suspend_user
{
    background-color: #8dbf42 !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    /* width: 45%; */
    color: white;
    cursor: pointer;
}
.unSuspend_user
{
    background-color:#e7515a !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    /* width: 45%; */
    color: white;
    cursor: pointer;
}