.switchery-default.on {
    background-color: #40a944;
    border-color: #40a944;
    box-shadow: #40a944 0px 0px 0px 10px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;  
}

.switchery-default.on > small {
    left: 17px;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
}

.switchery-default.off {
    background-color: rgb(255, 255, 255);
    border-color: rgb(223, 223, 223);
    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
}

.switchery-default.off > small {
    left: 0px;
    transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
}

.switchery {
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    display: block;
    line-height: 1;
    width: 2.25rem;
    height: 1.125rem;
    position: relative;
    box-sizing: content-box;
    border-radius: 100px;
}

.switchery>small {
    background-color: #fff;
    width: 1.125rem;
    height: 1.125rem;
    position: absolute;
    top: -0.125px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4);
    border-radius: 100px;
}
.form-group label, label {
    color: #111111;
}

.Suspend_user_tech
{
    background-color: #8dbf42 !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    width: 70%;
    color: white;
    cursor: pointer;
}
.unSuspend_user_tech
{
    background-color:#e7515a !important;
    padding: 3px;
    text-align: center;
    margin: 2px;
    border-radius: 5px;
    width:  70%;
    color: white;
    cursor: pointer;
}
/* .table1
{
    widows: 100% !important;
    overflow-x: scroll;
} */

span.tc_user_lbl {
    width: 20%;
    display: inline-block;
}
.customTechModalDetails {
    height: 80vh;
    overflow: auto;
}