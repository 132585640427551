
 
  
  .people-list {
    width: 260px;
    float: left;
  }
  .people-list .search {
    padding: 20px;
  }
  .people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
  }
  .people-list .fa-search {
    position: relative;
    left: -25px;
  }
  .people-list ul {
    padding: 20px;
    height: 770px;
  }
  .people-list ul li {
    padding-bottom: 20px;
  }
  .people-list img {
    float: left;
  }
  .people-list .about {
    float: left;
    margin-top: 8px;
  }
  .people-list .about {
    padding-left: 8px;
  }
  .people-list .status {
    color: #92959E;
  }
  
  .chat {
    width: 100%;
    float: left;
    /* background: #F2F5F8; */
    color: #434651;
  }
  .chat .chat-header {
    padding: 10px 20px;
    border-bottom: 2px solid white;
  }
  .chat .chat-header img {
    float: left;
  }
  .chat .chat-header .chat-about {
    float: left;
    padding-left: 10px;
    margin-top: 6px;
  }
  .chat .chat-header .chat-with {
    font-weight: bold;
    font-size: 16px;
  }
  .chat .chat-header .chat-num-messages {
    color: #92959E;
  }
  .chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
  }
  .chat .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: scroll;
    height: 75vh;
  }
  .chat .chat-history .message-data {
    margin-bottom: 15px;
  }
  .chat .chat-history .message-data-time {
    color: #a8aab1;
    padding-left: 6px;
  }
  .chat .chat-history .message {
    color: white;
    padding: 8px 20px;
    line-height: 25px;
    font-size: 14px;
    border-radius: 7px;
    margin-bottom: 10px;
    width: 90%;
    position: relative;
  }
  
  .chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #EBECF1;
    border-width: 10px;
    margin-left: -10px;
  }
  .chat .chat-history .my-message {
    background: #EBECF1;
    color: #000000;
  }
  .chat .chat-history .other-message {
    background: #d82b2a;
  }
  .chat .chat-history .other-message:after {
    border-bottom-color: #d82b2a;
    left: 93%;
  }
  
  .online, .offline, .me {
    margin-right: 3px;
    font-size: 10px;
  }
  
  .online {
    color: #86BB71;
  }
  
  .offline {
    color: #E38968;
  }
  
  .me {
    color: #d82b2a;
  }
  
  .align-left {
    text-align: left;
  }
  
  .align-right {
    text-align: right;
  }
  
  .float-right {
    float: right;
  }
  
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  
  .no-pad{
    padding-left: 0px;
    padding-right: 0px;
  }
  
  header.cd-main-header.js-cd-main-header{
    width: 100%;
  }
  
  .cd-main-header{
    position: fixed;
  }
  
  .custom-top-h5{
    width: 100%;
  /*  text-align: center;*/
  /*  position: absolute;*/
    font-size: 14px;
  }
  .activestatus_color
  {
    background: #EF233C;
    padding: 1px;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 8px;
    color: white;
  }