.cont__xs {
    width: 50%;
    max-width: 100% !important;
    margin-left: 25% !important;
}

.padding_sty
{
    padding: 5px;
}
.checkbox_cust_style
{
    padding: 10px;
    padding-top: 0px;
}

.right__menu_cr.text-center.m-b-5 {
    width: 33%;
    float: left;
}
.right__menu_cr.text-center.m-b-5 img {
    width: 140px;
    height: 150px;
}