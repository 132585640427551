.left-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
}

.left-nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    /*color: #444444;*/
    color: #000000;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.left-nav__link:hover, .left-nav__link:hover .material-icons {
    background-color: #eeeeee;
    color: #d82b2a;
    text-decoration: blink;
}

.left-nav__link--active {
    color: #d82b2a;
    /*    border-top: 2px solid;*/
}

.left-nav__icon {
    font-size: 18px;
}

.left__btm_menu, .right__btm_menu {
    width: 50%
}

.left-nav__link.lf {
    width: 45%;
    float: left; 
    margin-top: 5px;
}

.left-nav__link.rt {
    width: 45%;
    float: right;
    margin-top: 5px;
}

.highlight__menu {
    bottom: 27px;
    position: fixed;
    left: 44%;
    padding: 15px 10px 10px 10px;
    border-radius: 50%;
    background: #111111;
}

.highlight__menu i {
    color: #ffffff;
}
