.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}
.profile_padd_left
{
    margin-left: 26% !important;
}
