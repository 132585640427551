.table1 {
    width: 100%;
}
.pg-top {
    padding:2px;
}

span.tc_user_lbl_expand {
    width: 6%;
    display: inline-block;
}