.react-datepicker-wrapper
{
    display: block !important;
    /* width: 0%; */
}
.form_cut_dtyle
{
    width: 23%;
    float: left;
    margin-right: 30px;
}
.set_mini_height
{
    min-height: 300px;
}
.cust_top
{
    margin-top: 35px;
}

.cust_style_dislaynone
{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 30px;
}