/* @import '/assets/fonts/iconic/font-awesome-4.7.0/css/font-awesome.min.css'; */
/* @import '/assets/fonts/iconic/css/material-design-iconic-font.min.css'; */

@import '/assets/css/bootstrap.css';
@import '/assets/css/util.css';
@import '/assets/css/main1.css';
@import '/assets/css/main.css';

/* @import '/assets/css/custom.css';
@import '/assets/css/bootstrap.css';
@import '/assets/css/plugins.css';
@import '/assets/css/structure.css';
@import '/assets/css/main.css';
@import '/assets/css/main1.css';
@import '/assets/css/dash_1.css';
@import '/assets/css/dash_2.css';
@import '/assets/css/theme-checkbox-radio.css.css';
@import '/assets/css/contacts.css';
@import '/assets/css/animate.css';
@import '/assets/css/scrollspyNav.css';
@import '/assets/css/custom-modal.css';
@import '/assets/css/user-profile.css';
@import '/assets/css/tags-input.css';
@import '/assets/css/table-basic.css';
@import '/assets/css/datatables.css';
@import '/assets/css/dt-global_style.css';
@import '/assets/css/file-upload-with-preview.min.css';
@import '/assets/css/perfect-scrollbar.css';
@import '/assets/css/flatpickr.css';
@import '/assets/css/custom-flatpickr.css'; */

:root {
    --primary-theme-color: #d90429;
}
  
.sidebar-theme {
    background: var(--primary-theme-color);
}

a.fr.btn.btn-danger {
    background-color: var(--primary-theme-color);
    border-color: var(--primary-theme-color);
}

.customSwitchBtn.activeswitchclass .react-switch-bg {
    background: var(--primary-theme-color);
}

.add_btn_icon_style {
    background: var(--primary-theme-color);
}